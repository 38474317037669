<template>
  <!-- 规格修改页面 -->
  <moe-page title="规格值管理">
    <moe-form ref="specsAmendForm" :model="specsAmend">
      <el-form-item label="规格名称" prop="name">
        {{ specsAmend.name }}
      </el-form-item>

      <el-form-item label="规格值" :prop="`specsValues.${index}.value`" v-for="(item, index) of specsAmend.specsValues" :key="index" :rules="specsAmendRules.value">
        <div class="df dac">
          <el-input class="mr-10" v-model.trim="item.value" placeholder="请输入规格值" maxlength="50" clearable :disabled="specsAmend.specsValues[index].id ? true : false" />
          <el-button type="text" icon="el-icon-delete" v-show="specsAmend.specsValues.length !== index" @click="cerealNameDelete(item,index)" />
        </div>
      </el-form-item>

      <el-button type="warning" icon="el-icon-add" @click="cerealNameAdd()">添加规格值</el-button>

      <template slot="tool" v-if="addIds.length">
        <el-button type="primary" icon="el-icon-upload2" :loading="specsAmendLoad" @click="insertShopSpecsValue()">
          {{ specsAmendLoad ? '保存中' : '保存' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'SpecsAmend',
  data() {
    //账号信息
    const specsAmend = {
      id: "",
      name: '', //名称
      specsValues: []
    };

    //账号数据校验
    const verify = this.$moe_verify.verifyForm;
    const specsAmendRules = {
      value: verify.isEmpty('请输入规格值'),
    };

    return {
      specsAmend, //规格信息
      specsAmendRules, //规格数据校验
      specsAmendLoad: false, //提交状态
      specsDelList: [],
      addIds: [],
      showDialog: false,
    };
  },
  created() {
    //获取规格信息
    this.getSpecsAmendDetail();
    this.getSpecsValueDetail();
  },
  methods: {
    /** 获取规格详情 */
    getSpecsAmendDetail() {
      // 获取规格ID
      this.specsAmend.id = this.$route.query.id;
      // 查看规格详情
      this.$moe_api.GOODS_API.specsAmendDetail({ id: this.specsAmend.id }).then((data) => {
        if (data.code == 200) {
          let { name } = data.result;
          this.specsAmend.name = name
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /** 获取规格值 */
    getSpecsValueDetail() {
      // 获取规格ID
      const specsId = this.$route.query.id;
      // 查看规格详情
      this.$moe_api.GOODS_API.specsValueDetail({ specsId }).then((data) => {
        if (data.code == 200) {
          let a = data.result;
          this.specsAmend.specsValues = a
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /** 规格值删除 */
    cerealNameDelete(item, index) {
      if (item.id) {
        this.$moe_layer.confirm('是否要删除该规格值 ?', () => {
          this.$moe_api.GOODS_API.deleteShopSpecsValue({ ids: item.id })
            .then((data) => {
              if (data.code == 200) {
                this.$moe_msg.success('删除成功');
                this.getSpecsAmendDetail();
                this.getSpecsValueDetail();
              } else {
                this.$moe_msg.error(data.message);
              }
            })
            .finally(() => {
              this.specsAmendLoad = false;
            });
        })
      } else {
        this.specsAmend.specsValues.splice(index, 1);
        this.addIds = this.specsAmend.specsValues.filter(({ id }) => !id)
      }
    },

    /** 规格值添加 */
    cerealNameAdd() {
      this.specsAmend.specsValues.push({
        value: '',
        specId: this.$route.query.id
      });
      this.addIds = this.specsAmend.specsValues.filter(({ id }) => !id)
    },

    /** 规格值添加接口请求 **/
    insertShopSpecsValue() {
      this.$refs['specsAmendForm'].validate(() => {
        this.specsAmendLoad = true
        // 调用规格值新增API
        this.$moe_api.GOODS_API.insertShopSpecsValue(this.addIds)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功')
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message)
            }
          })
          .finally(() => {
            this.specsAmendLoad = false
          })
      })
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
